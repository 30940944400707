(functionwebpackUniversalModuleDefinition(root,factory){
	if(typeofexports==='object'&&typeofmodule==='object')
		module.exports=factory();
	elseif(typeofdefine==='function'&&define.amd)
		define([],factory);
	elseif(typeofexports==='object')
		exports["PureCounter"]=factory();
	else
		root["PureCounter"]=factory();
})(self,function(){
return