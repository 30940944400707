exportdefaultclassPureCounter{
constructor(options={}){
/**Defaultconfigurations*/
this.defaults={
start:0,//Startingnumber[uint]
end:100,//Endnumber[uint]
duration:2000,//Countduration[milisecond]
delay:10,//Countdelay[milisecond]
once:true,//Countingatonceorrecountwhenscroll[boolean]
pulse:false,//Pulsecountforcertaintime[boolean|milisecond]
decimals:0,//Decimalplaces[uint]
legacy:true,//Ifthisistrueitwillusethescrolleventlisteneronbrowsers
filesizing:false,//Isitforfilesize?
currency:false,//Isitforcurrency?Useitforsetthesymboltoo[boolean|char|string]
separator:false,//Doyouwanttousethausandsseparator?useitforsetthesymboltoo[boolean|char|string]
formater:"us-US",//NumbertoLocaleStringlocale/formater,bydefaultis"en-US"[string|boolean:false]
selector:".purecounter",//HTMLqueryselectorforspesificelement
};
/**Setdefaultconfigurationbasedonuserinput*/
this.configOptions=this.setOptions(options,this.defaults);
/**Getallelemenetsbasedondefaultselector*/
this.elements=document.querySelectorAll(this.configOptions.selector);
/**GetbrowserIntersectionListenerSupport*/
this.intersectionSupport=this.intersectionListenerSupported();
/**Initiateeventlistened*/
this.registerEventListeners();
}

/**Thismethodisforcreateandmergeconfiguration*/
setOptions(config,baseConfig={}){
//CreatenewConfigobject;
varnewConfig={};
//LoopconfigitemstosetitvalueintonewConfig
for(varkeyinconfig){
//ifbaseConfigisset,onlyacceptthebaseconfigproperty
if(Object.keys(baseConfig).length!==0&&!baseConfig.hasOwnProperty(key))
continue;
//varparsetheconfigvalue
varval=this.parseValue(config[key]);
//setthenewConfigpropertyvalue
newConfig[key]=val;
//Exclusivefor'duration'or'pulse'property,recheckthevalue
//Ifit'snotaboolean,justsetittomiliseconduint
if(key.match(/duration|pulse/)){
newConfig[key]=typeofval!="boolean"?val*1000:val;
}
}

//Finally,wecanjustmergethebaseConfig(ifany),withnewConfig.
returnObject.assign({},baseConfig,newConfig);
}

/**Initialsetupmethod*/
registerEventListeners(){
/**Getallelementswithclass'purecounter'*/
varelements=this.elements;
/**Returnifnoelements*/
if(elements.length===0)return;

/**RunanimateElementsbaseonIntersectionSupport*/
if(this.intersectionSupport){
varintersectObserver=newIntersectionObserver(
this.animateElements.bind(this),
{
root:null,
rootMargin:"20px",
threshold:0.5,
}
);

elements.forEach((element)=>{
intersectObserver.observe(element);
});
}else{
if(window.addEventListener){
this.animateLegacy(elements);
window.addEventListener(
"scroll",
function(e){
this.animateLegacy(elements);
},
{passive:true}
);
}
}
}

/**ThislegacytomakePurecounteruseverylightweight&fast*/
animateLegacy(elements){
elements.forEach((element)=>{
varconfig=this.parseConfig(element);
if(config.legacy===true&&this.elementIsInView(element)){
this.animateElements([element]);
}
});
}

/**MainElementCountAnimation*/
animateElements(elements,observer){
elements.forEach((element)=>{
varelm=element.target||element;//Justmakesurewhichelementwillbeused
varelementConfig=this.parseConfig(elm);//Getconfigvalueonthatelement

//console.log(elementConfig);
//Ifdurationislessthanorequalzero,justformatthe'end'value
if(elementConfig.duration<=0){
return(elm.innerHTML=this.formatNumber(
elementConfig.end,
elementConfig
));
}

if(
(!observer&&!this.elementIsInView(element))||
(observer&&element.intersectionRatio<0.5)
){
varvalue=
elementConfig.start>elementConfig.end
?elementConfig.end
:elementConfig.start;
return(elm.innerHTML=this.formatNumber(value,elementConfig));
}

//Ifdurationismorethan0,thenstartthecounter
setTimeout(()=>{
returnthis.startCounter(elm,elementConfig);
},elementConfig.delay);
});
}

/**Thisisthethecountermethod*/
startCounter(element,config){
//First,gettheincrementsstep
varincrementsPerStep=
(config.end-config.start)/(config.duration/config.delay);
//Next,setthecountermode(IncrementorDecrement)
varcountMode="inc";

//Setmodeto'decrement'and'incrementstep'tominusifstartislargerthanend
if(config.start>config.end){
countMode="dec";
incrementsPerStep*=-1;
}

//Next,determinethestartingvalue
varcurrentCount=this.parseValue(config.start);
//Andthenprintit'svaluetothepage
element.innerHTML=this.formatNumber(currentCount,config);

//Iftheconfig'once'istrue,thensetthe'duration'to0
if(config.once===true){
element.setAttribute("data-purecounter-duration",0);
}

//Now,startcountingwithcounterWorkerusingIntervalmethodbasedondelay
varcounterWorker=setInterval(()=>{
//First,determinethenextvaluebaseoncurrentvalue,incrementvalue,andcountmode
varnextNum=this.nextNumber(currentCount,incrementsPerStep,countMode);
//Next,printthatvaluetothepage
element.innerHTML=this.formatNumber(nextNum,config);
//Nowsetthatvaluetothecurrentvalue,becauseit'salreadyprinted
currentCount=nextNum;

//Ifthevalueislargerorlessthanthe'end'(baseonmode),thenprinttheendvalueandstoptheInterval
if(
(currentCount>=config.end&&countMode==="inc")||
(currentCount<=config.end&&countMode==="dec")
){
element.innerHTML=this.formatNumber(config.end,config);
//If'pulse'issetignorethe'once'config
if(config.pulse){
//Firstsetthe'duration'tozero
element.setAttribute("data-purecounter-duration",0);
//Next,usetimeouttoresetitdurationbackbasedon'pulse'config
setTimeout(()=>{
element.setAttribute(
"data-purecounter-duration",
config.duration/1000
);
},config.pulse);
}
//Now,wecanclosetheconterWorkerpeacefully
clearInterval(counterWorker);
}
},config.delay);
}

/**ThismethodistogeneratetheelementConfig*/
parseConfig(element){
//Next,getall'data-precounter-*'attributesvalue.Storetoarray
varconfigValues=[].filter.call(element.attributes,function(attr){
return/^data-purecounter-/.test(attr.name);
});

//Now,wecreateelementconfigasanobject
varelementConfig=
configValues.length!=0
?Object.assign(
{},
...configValues.map(({name,value})=>{
varkey=name.replace("data-purecounter-","").toLowerCase(),
val=this.parseValue(value);

return{[key]:val};
})
)
:{};

//LastsetOptionsandreturn
returnthis.setOptions(elementConfig,this.configOptions);
}

/**Thismethodistogetthenextnumber*/
nextNumber(number,steps,mode="inc"){
//First,gettheexactvaluefromthenumberandstep(intorfloat)
number=this.parseValue(number);
steps=this.parseValue(steps);

//Last,getthenextnumberbasedoncurrentnumber,incrementstep,andcountmode
//Alwaysreturnitasfloat
returnparseFloat(mode==="inc"?number+steps:number-steps);
}

/**Thismethodistogettheconvertednumber*/
convertNumber(number,config){
/**Useconverteriffilesizingorcurrencyison*/
if(config.filesizing||config.currency){
number=Math.abs(Number(number));//Gettheabsolutevalueofnumber

varbaseNumber=1000,//Basemultiplyingtreshold
symbol=
config.currency&&typeofconfig.currency==="string"
?config.currency
:"",//SettheCurrencySymbol(ifany)
limit=config.decimals||1,//Setthedecimallimit(defaultis1)
uint=["","K","M","B","T"],//Numberuintbasedexponentthreshold
value="";//Definevaluevariable

/**Changesbasenumberanditsuintforfilesizing*/
if(config.filesizing){
baseNumber=1024;//Use1024insteadof1000
uint=["bytes","KB","MB","GB","TB"];//Changeto'bytes'uint
}

/**Setvaluebasedonthethreshold*/
for(vari=4;i>=0;i--){
//Iftheexponentis0
if(i===0)value=`${number.toFixed(limit)}${uint[i]}`;
//Iftheexponentisabovezero
if(number>=this.getFilesizeThreshold(baseNumber,i)){
value=`${(
number/this.getFilesizeThreshold(baseNumber,i)
).toFixed(limit)}${uint[i]}`;
break;
}
}

//Applysymbolbeforethevalueandreturnitasstring
returnsymbol+value;
}else{
/**Returnitsvalueasfloatifnotusingfilesizingorcurrency*/
returnparseFloat(number);
}
}

/**Thismethodwillgetthegivenbase.*/
getFilesizeThreshold(baseNumber,index){
returnMath.pow(baseNumber,index);
}

/**Thismethodistogetthelastformatednumber*/
applySeparator(value,config){
//Getreplacedvaluebasedonit'sseparator/symbol.
functionreplacedValue(val,separator){
//WellthisismyregExpfordetectingtheThausandsSeparator
//Iuse3groupstodetermineit'sseparator
//THenthegroup4istogetthedecimalsvalue
varseparatorRegExp=
/^(?:(\d{1,3},(?:\d{1,3},?)*)|(\d{1,3}\.(?:\d{1,3}\.?)*)|(\d{1,3}(?:\s\d{1,3})*))([\.,]?\d{0,2}?)$/gi;

returnval.replace(separatorRegExp,function(match,g1,g2,g3,g4){
//setinitialresultvalue
varresult="",
sep="";
if(g1!==undefined){
//Group1isusingcommaasthausandsseparator,andperiodasdecimalseparator
result=g1.replace(newRegExp(/,/gi,"gi"),separator);
sep=",";
}elseif(g2!==undefined){
//Group2isusingperiodasthausandsseparator,andcommaasdecimalseparator
result=g2.replace(newRegExp(/\./gi,"gi"),separator);
}elseif(g3!==undefined){
//Group3isusingspaceasthausandsseparator,andcommaasdecimalseparator
result=g3.replace(newRegExp(//gi,"gi"),separator);
}
if(g4!==undefined){
vardecimal=sep!==","?(separator!==","?",":"."):".";
result+=g4.replace(newRegExp(/\.|,/gi,"gi"),decimal);
}
//Returningresultvalue;
returnresult;
});
}
//Ifconfigformaterisnotfalse,thenapplyseparator
if(config.formater){
//Nowgettheseparatorsymbol
varsymbol=config.separator//ifconfigseparatorissetted
?typeofconfig.separator==="string"//Checkthetypeofvalue
?config.separator//Ifit'stypeisstring,thenapplyit'svalue
:","//Ifit'snotstring(boolean),thenapplycommaasdefaultseparator
:"";
//Specialexceptionwhenlocaleisnot'en-US'butseparatorvalueis'true'
//Useit'sdefaultlocalethausandsseparator.
if(config.formater!=="en-US"&&config.separator===true){
returnvalue;
}
//ReturnthereplacedValuebasedonit'ssymbol
returnreplacedValue(value,symbol);
}
//Ifconfigformaterisfalse,thenreturnit'sdefaultvalue
returnvalue;
}

/**Thismethodistogetformatednumbertobeprintedinthepage*/
formatNumber(number,config){
//Thisistheconfigurationfor'toLocaleString'method
varstrConfig={
minimumFractionDigits:config.decimals,
maximumFractionDigits:config.decimals,
};
//Getlocalefromconfigformater
varlocale=typeofconfig.formater==="string"?config.formater:undefined;

//Setandconvertthenumberbaseonitsconfig.
number=this.convertNumber(number,config);

//Nowformatthenumbertostringbaseonit'slocale
number=config.formater
?number.toLocaleString(locale,strConfig)
:parseInt(number).toString();
//Last,applythenumberseparatorusingnumberasstring
returnthis.applySeparator(number,config);
}

getLocaleSeparator(){
varregExp=
/^(?:(\d{1,3}(?:,\d{1,3})*(?:\.\d{0,3})?)|(\d{1,3}(?:\.\d{1,3})*(?:,\d{0,3})?)|(\d{1,3}(?:\s\d{1,3})*(?:,\d{0,3})?))$/i;
return;
}

/**Thismethodistogettheparsedvalue*/
parseValue(data){
//Ifnumberwithdot(.),willbeparsedasfloat
if(/^[0-9]+\.[0-9]+$/.test(data)){
returnparseFloat(data);
}
//Ifjustnumber,willbeparsedasinteger
if(/^[0-9]+$/.test(data)){
returnparseInt(data);
}
//Ifit'sbooleanstring,willbeparsedasboolean
if(/^true|false/i.test(data)){
return/^true/i.test(data);
}
//Returnit'svalueasdefault
returndata;
}

/**Thismethodistodetecttheelementisinviewornot.*/
elementIsInView(element){
vartop=element.offsetTop;
varleft=element.offsetLeft;
varwidth=element.offsetWidth;
varheight=element.offsetHeight;

while(element.offsetParent){
element=element.offsetParent;
top+=element.offsetTop;
left+=element.offsetLeft;
}

return(
top>=window.pageYOffset&&
left>=window.pageXOffset&&
top+height<=window.pageYOffset+window.innerHeight&&
left+width<=window.pageXOffset+window.innerWidth
);
}

/**JustsomeconditiontocheckbrowserIntersectionSupport*/
intersectionListenerSupported(){
return(
"IntersectionObserver"inwindow&&
"IntersectionObserverEntry"inwindow&&
"intersectionRatio"inwindow.IntersectionObserverEntry.prototype
);
}
}
